import { enumValueGuard } from '@fmnts/core';

/**
 * An enum that has a unique value for each type of domain entity in our system.
 */
export enum DomainEntityType {
  Achievement = 'achievement',
  Donation = 'donation',
  DonationFeedback = 'donation-feedback',
  Fundraiser = 'fundraiser',
  Location = 'location',
  Quiz = 'quiz',
  Team = 'team',
}
export type DomainEntityTypes = `${DomainEntityType}`;

/**
 * Fixed error type to indicate if and how this error can be shown to the user or not.
 */
export enum ApiErrorType {
  Conflict = 'conflict_error',
  Validation = 'validation_error',
  Permission = 'permission_error',
  Client = 'client_error',
  Server = 'server_error',
}

/**
 * Identifies a type that can be a target of some action / activity.
 */
export enum ApiTargetObjectType {
  Achievement = 'Achievement',
  Donation = 'Donation',
  Feedback = 'DonationFeedback',
}

/**
 * Identifies a type that can be the actor upon some action / activity.
 */
export enum ApiActorObjectType {
  Fundraiser = 'Fundraiser',
}

export enum CustomerFeature {
  Gamification = 'gamification',
  JobReferralLeaderboard = 'job_referral_leaderboard',
  LocationTracking = 'location_tracking',
  LocationModule = 'location_module',
  FundraiserQuizzes = 'fundraiser_quizzes',
  Marketplace = 'marketplace',
  MultiCampaignSetup = 'multi_campaign_setup',
  Launcher = 'launcher',
}
export type CustomerFeatures = `${CustomerFeature}`;

/**
 * Types of forms supported by Formunauts.
 */
export enum FormType {
  /** Donation form. */
  Donation = 'donation',
  /** Petition form. */
  Petition = 'petition',
  /** Job application form. */
  JobApplication = 'job-application',
}
/**
 * @see {@link FormType}
 */
export const isFormType = enumValueGuard(FormType);
/**
 * List of all form types
 * @see {@link FormType}
 */
export const ALL_FORM_TYPES = [
  FormType.Donation,
  FormType.Petition,
  FormType.JobApplication,
] as const;

/**
 * Types that a metric can have.
 *
 * @deprecated
 * Should only be used by legacy APIs.
 */
export enum LegacyMetricsType {
  Average = 'average',
  Buckets = 'buckets',
  Duration = 'duration',
  Metric = 'metric',
  Money = 'money',
  Percentage = 'percent-of',
}
/**
 * Types that a metric can have.
 * The value of a metric is defined by the metric type.
 */
export enum MetricType {
  Average = 'average',
  /** Only used on the FE side. */
  Duration = 'duration',
  Numeric = 'numeric',
  KeyValueList = 'key-value-list',
  TextList = 'text-list',
}
/**
 * @see {@link MetricType}
 */
export const isMetricType = enumValueGuard(MetricType);

/**
 * Display type of a metric.
 *
 * This is relevant for displaying the metric.
 */
export enum MetricDisplayType {
  BarChartHorizontal = 'horizontal-bar-chart',
  BarChartVertical = 'vertical-bar-chart',
  Duration = 'duration',
  Metric = 'metric',
  Money = 'money',
  Percentage = 'percent-of',
  PieChart = 'pie-chart',
  Rating = 'rating',
  TextList = 'text-list',
}
/**
 * @see {@link MetricDisplayType}
 */
export const isMetricDisplayType = enumValueGuard(MetricDisplayType);

export enum LocationType {
  Public = 'public',
  Private = 'private',
  Unknown = 'unknown',
}

/**
 * Status of an uploaded photo verification.
 */
export enum PhotoVerificationStatus {
  /** Uploaded photo was accepted by some user. */
  Accepted = 'accepted',
  /** No user did accept or refuse the photo. */
  Pending = 'pending',
  /** Uploaded photo was refused by some user. */
  Refused = 'refused',
}

export enum QuizStatus {
  Open = 'open',
  Passed = 'passed',
  Failed = 'failed',
  MaxAttemptsReached = 'max_attempts_reached',
  Expired = 'expired',
}

/**
 * Perspective of which statistical information is gathered.
 */
export enum StatisticsPerspective {
  Fundraisers = 'fundraisers',
  Teams = 'teams',
}
/**
 * @see {@link StatisticsPerspective}
 */
export const isStatisticsPerspective = enumValueGuard(StatisticsPerspective);

/**
 * Knows views for which statistical informatino is gathered.
 */
export enum NextStatisticsViewId {
  Launcher = 'launcher',
}
