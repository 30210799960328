// TODO(domain-interfaces): Move these imports here or decouple
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EntityId, Page } from '@fmnts/api';
import { InlineTranslateable as Translatable } from '@fmnts/i18n';
import * as Data from 'effect/Data';
import * as DateTime from 'effect/DateTime';

export { EntityId, Page, Translatable };

/**
 * A date period.
 */
export interface DatePeriod {
  readonly from: Date;
  readonly to: Date;
}
export const DatePeriod = Data.case<DatePeriod>();

/**
 * Information about when something was created / modified.
 */
export interface ModifyTracking {
  readonly createdAt: DateTime.Utc;
  readonly modifiedAt: DateTime.Utc;
}
export const ModifyTracking = Data.case<ModifyTracking>();
