import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import * as Config from './config.model';

export function provideSharedDataAccess(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: Config.Timeout,
      useValue: new Config.Timeout({
        general: 5_000,
      }),
    },
  ]);
}
